import React from 'react'
import styles from "./solv-active-css-module.module.scss"
import { Layout } from '../components'
import { Button } from "../components";
import { Link, navigate } from "gatsby"
import solvSureActiveLogo from "../../static/solvSureActiveLogo.svg"
import scanQrCode from "../../static/scanQrCode.png"
import activeTick from "../../static/activeTick.svg"

const solvSureActive = () => {
  function handleContactUs(){
    navigate("/contact")
  }
  return (
      <div>
          <Layout>
              <section className={styles.solvSureActive}>
                  <div className={[styles.activeHeading,  "heading1SemiBold"].join(" ")}>Solv Sure Active - Protect your health and your business</div>
                  <div className={styles.activeTitle}>Solv is offering small businesses a reliable way to protect what matters – their health and their store. Solv Sure Active is a comprehensive care package that aids business continuity and provides you benefits worth ₹5000 for just ₹1500.</div>
                  <div className={styles.activeTitle}><strong>Get cashback of ₹1500 (in the form of discount vouchers) when you purchase this package and complete transactions worth ₹50,000 on the Solv platform from within a year of purchase.</strong> </div>
                  <Link className={styles.termCondition} to="/promos/solvsureactiveoffer/tnc/" style={{"textDecoration": "underline"}}>Terms and conditions apply.</Link>
                  <div className={styles.startButon}>
                      <Button label="Contact Us &nbsp; &#8594;" handleClick={handleContactUs}/>
                  </div>

                  <div className={styles.activeBox}>
                      <div className={styles.flexArrange}>
                          <div className={styles.logoCircle}>
                              <img src={solvSureActiveLogo} alt="logo" />
                          </div>
                          <div>
                              <div className={styles.boxHeader}>Solv Sure Active</div>
                              <div className={styles.boxTitle}>Benefits worth ₹5,000 for <span className={styles.titleBold}>₹1,500</span> Only</div>
                          </div>
                          {/* <div >
                              <Button label="Contact Us &nbsp; &#8594;" />
                          </div> */}
                      </div>
                          <div className={styles.line}></div>

              <div className={styles.detailsPadding}>
                    <div className={styles.subDetailBox}>
                      <img src={activeTick} alt="logo" />
                      <div  className={styles.value}><strong>₹ 1,500 </strong>worth Netmed vouchers</div>
                    </div>

                    <div className={styles.subDetailBox}>
                    <img src={activeTick} alt="logo" />
                      <div  className={styles.value}><strong>Health check-up vouchers</strong> (61 tests) for 2 members</div>
                    </div>

                    <div className={styles.subDetailBox}>
                    <img src={activeTick} alt="logo" />
                      <div  className={styles.value}><strong>Diet-fit program voucher </strong> 1 Member</div>
                    </div>

                    <div className={styles.subDetailBox}>
                    <img src={activeTick} alt="logo" />
                      <div  className={styles.value}><strong>Unlimited tele-consultation </strong>with doctors 2 Members</div>
                    </div>

                    <div className={styles.subDetailBox}>
                    <img src={activeTick} alt="logo" />
                      <div  className={styles.value}><strong>Network discount card for discounts </strong>at 1000+ hospitals, clinics and pharmacies</div>
                    </div>

                    <div className={styles.subDetailBox}>
                    <img src={activeTick} alt="logo" />
                      <div  className={styles.value}><strong>₹ 1,00,000</strong> Sum insured for fire, flood and burglary </div>
                    </div>    

                      <div className={styles.noteHeader}>Note:</div>
                      <div className={styles.note}>
                          <div>* The above are estimated amounts if all benefits are fully utilized. </div>
                          <Link className={[styles.termAndCondition].join(' ')} to="/promos/solvsureactiveoffer/tnc/">* Check our <span style={{"textDecoration": "underline"}}>terms & conditions.</span></Link>
                      </div>
              </div>
          </div>

              <div className={styles.qrCode}>
                  <div className={styles.scanBox}>
                      <img src={scanQrCode} alt="logo" /> 
                      <div>
                          <div className={styles.key}>VPA: <strong>solvsure@s</strong></div>
                          <div className={styles.key}>Name: <strong>STANDARD CHARTERED RESEARCH AND TECHNOLOGY INDIA PRIVATE LTD</strong></div>
                      </div>
                  </div>
                  <div className={styles.information}>SOLV will not accept any cash payment. The payments towards the services need to be done by UPI ( solvsure@sc or QR Code), IMPS/NEFT (Standard Chartered Bank, A/C No: 45505397405 & IFSC: SCBL0036073) or by cheque in the name of “Standard Chartered Research & Technology India Pvt. Ltd.”</div>
              </div>

                </section>
          </Layout>
      </div>
  )
}

export default solvSureActive
